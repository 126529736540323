import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsTraders from './components/LanguageOptionsTraders';
import LanguageOptionsInvestors from './components/LanguageOptionsInvestors'
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';
import SignUp from './components/SignUp';




const content1 = {
  title: "Key Responsibilities:",
  content: [
          "- Identify & Assess Investment Opportunities: Analyze cryptocurrency markets and blockchain projects to uncover high-potential investment opportunities.",
          "- Portfolio Management: Build, manage, and optimize a diversified portfolio of cryptocurrencies, DeFi protocols, and blockchain-related assets.",
          "- Market Analysis: Monitor market trends, emerging technologies, and developments in the blockchain space to inform investment decisions.",
          "- Strategic Decision-Making: Provide data-driven insights and recommendations to guide both short-term and long-term investment strategies.",
          "- Collaboration: Work closely with internal teams, including product, research, and strategic development teams, to integrate investment insights into broader company initiatives.",
          "- Risk Management: Develop and implement strategies to mitigate risks and maximize returns in a volatile market environment."
    ]
}

const content2 = {
    title: "Required Skills And Qualifications:",
    content: [
              "- Experience: 3-5 years of experience in cryptocurrency or blockchain investment and portfolio management.",
              "- Analytical Skills: Strong ability to evaluate market trends, data, and assets using analytical tools (e.g., Python, R, SQL).",
              "- Market Understanding: In-depth knowledge of cryptocurrency, Ethereum, DeFi, and blockchain ecosystems.",
              "- Decision-Making: Proven ability to make high-stakes investment decisions with an eye for growth and risk management.",
              "- Communication Skills: Excellent written and verbal communication skills for presenting investment findings and reports.",
              "- Education: A degree in finance, economics, business, or related fields is preferred.",
      ]
  }
  

const companyURL = "https://ConsenSys.io/";


function AppIsofu() {
  const { state, setData } = useAppContext();
  const [showModal, setShowModal] = useState(true);

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="Remote" salary="$200K/yr - $250K/yr" company="ConsenSys" companyURL={companyURL} />
          <LanguageOptionsInvestors company="Gemini" title="Cryptocurrency Investor" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
      {
        showModal? <SignUp callback={()=>setShowModal(false)}/>
        :<></>
      }
    </div>
  );
}

export default AppIsofu;
