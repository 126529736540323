import React, {useEffect, useState} from 'react';
import Header from './components/Header';
import InformationCard from './components/InformationCard';
import LanguageOptionsTraders from './components/LanguageOptionsTraders';
import Footer from './components/Footer';
import { useAppContext } from './Provider/AppProvider';
import SignUp from './components/SignUp';




const content1 = {
  title: "Key Responsibilities:",
  content: [
          "- Lead DeFi Research: Conduct deep market research to identify emerging DeFi protocols, trends, and innovations across Ethereum and Layer 2 solutions.",
          "- Evaluate DeFi Projects: Assess and analyze DeFi protocols and platforms, evaluating their technology, economic models, and security risks to recommend potential investments, integrations, or partnerships.",
          "- Strategic Insights & Forecasting: Provide actionable insights and forecasts to influence the strategic direction of ConsenSys’ DeFi-related investments, products, and services.",
          "- Risk & Security Analysis: Identify, assess, and mitigate risks associated with DeFi protocols, including smart contract vulnerabilities, market volatility, and regulatory concerns.",
          "- Thought Leadership & Content: Create thought leadership content and deliver presentations on DeFi innovations, market trends, and best practices to both internal and external stakeholders.",
          "- Cross-Functional Collaboration: Work closely with product development, marketing, and business development teams to inform and guide DeFi-focused initiatives and product roadmaps.",
          "- Community Engagement: Participate in DeFi-focused communities, DAO (Decentralized Autonomous Organization) governance, and industry events to stay updated and network with key influencers and innovators."
    ]
}

const content2 = {
  title: "Required Qualifications:",
  content: [
            "- 3+ years of experience in DeFi, cryptocurrency, or blockchain research, with a strong focus on decentralized finance solutions.",
            "- Advanced expertise in Ethereum, smart contracts, Layer 2 solutions, and DeFi protocols (e.g., AMMs, lending platforms, synthetic assets, staking, governance models).",
            "- Strong analytical and quantitative skills, with hands-on experience in data analysis tools (Python, R, SQL, etc.), and a deep understanding of financial modeling and tokenomics.",
            "- Expertise in DeFi security issues, including understanding of smart contract audits, vulnerabilities, and risk management practices.",
            "- Familiarity with governance models in decentralized protocols (DAO governance), token economics, and the broader blockchain ecosystem.",
            "- Exceptional communication skills to convey complex technical and financial concepts to non-technical stakeholders.",
            "- A degree in finance, economics, computer science, or related fields is preferred. Advanced certifications in blockchain or DeFi is a plus.",
    ]
}

const companyURL = "https://consensys.io/";


function AppAladin() {
  const { state, setData } = useAppContext();
  const [showModal, setShowModal] = useState(true);

  useEffect(()=>{
    const newData = { companyURL }; // Example new data
    setData(newData);
    localStorage.setItem("company", companyURL);
  },[])

  return (
    <div className="App flex flex-col min-h-screen">
      <Header />
      <main className=" py-10">
        <div className="w-3/4 mx-auto flex space-x-6 flex-col md:flex-row">
          <InformationCard location="Remote" salary="$225,000 - $275,000/year" company="ConsenSys" companyURL={companyURL} />
          <LanguageOptionsTraders company="ConsenSys" title="DeFi(Decentralized Finance) Specialist" content1={content1} content2={content2}/>
        </div>
      </main>
      <Footer />
      {
        showModal? <SignUp callback={()=>setShowModal(false)}/>
        :<></>
      }
    </div>
  );
}

export default AppAladin;
